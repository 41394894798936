import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";

import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";

import { DataTableAvatars } from "./data";
import AddAvatarDialog from "./AddAvatarDialog";
import { Avatar } from "types";
import apiClient from "api";
import AvatarSchedules from "../AvatarSchedules";

const Avatars = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [loading, setLoading] = useState(false);
  const [openAddSetting, setOpenAddSetting] = useState(false);

  const [data, setData] = useState<Avatar[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar | null>(null);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchAvatars, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchAvatars = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("admin_creative_get_avatars", {
        headers: {
          request: JSON.stringify({ creative_id: account?.account_id }),
        },
      });
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Avatars</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Avatars
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAddSetting(true)}>
            Add avatar
          </Button>
        </div>
      </div>

      <DataTableAvatars
        data={data}
        loading={loading}
        fetchData={fetchAvatars}
        setSelectedAvatar={setSelectedAvatar}
      />

      <AvatarSchedules selectedAvatar={selectedAvatar} />

      <AddAvatarDialog
        fetchData={fetchAvatars}
        open={openAddSetting}
        onOpenChange={setOpenAddSetting}
      />
    </div>
  );
};

export default Avatars;
