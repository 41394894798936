import { Account, Agency, Client, CampaignList } from "types"
import { set } from "utils/storage"

type Refresh = "connections" | "schedules" | "posts" | "wordpress" | ""

export interface UserState {
    client: Client | undefined
    agency: Agency | undefined
    account: Account | undefined
    campaign: CampaignList | undefined
    account_token: string
    refresh: "connections" | "schedules" | "posts" | "wordpress" | ""
}

// let existingState = get("USER_STATE")

// existingState = existingState ? JSON.parse(existingState) : null


export const initialUserState: UserState = {
    client: undefined,
    agency: undefined,
    account: undefined,
    campaign: undefined,
    account_token: "",
    refresh: ""
}

export type UserAction =
    | { type: "SET_CLIENT", payload: Client | undefined }
    | { type: "SET_AGENCY", payload: Agency | undefined }
    | { type: "SET_ACCOUNT", payload: Account | undefined }
    | { type: "SET_CAMPAIGN", payload: CampaignList | undefined }
    | { type: "SET_ACCOUNT_TOKEN", payload: string }
    | { type: "SET_REFRESH", payload: Refresh }


const userReducer = (state: UserState, action: UserAction): UserState => {
    let newState = state;
    switch (action.type) {
        case 'SET_CLIENT':
            newState = { ...newState, client: action.payload };
            set("USER_STATE", JSON.stringify(newState))
            return newState
        case 'SET_AGENCY':
            newState = { ...newState, agency: action.payload };
            set("USER_STATE", JSON.stringify(newState))
            return newState
        case 'SET_ACCOUNT':

            if (!action.payload) {
                newState = { ...newState, account: action.payload, account_token: "" };
            } else {
                newState = { ...newState, account: action.payload };
            }

            set("USER_STATE", JSON.stringify(newState))
            return newState
        case 'SET_CAMPAIGN':
            newState = { ...newState, campaign: action.payload };
            set("USER_STATE", JSON.stringify(newState))
            return newState
        case 'SET_ACCOUNT_TOKEN':
            newState = { ...newState, account_token: action.payload };
            set("USER_STATE", JSON.stringify(newState))
            return newState
        case 'SET_REFRESH':
            newState = { ...newState, refresh: action.payload };
            return newState
        default:
            return newState;
    }

}

export default userReducer