import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import { useToast } from "components/ui/use-toast";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import CommonTable from "components/Shared/CommonTable";
import AddAgencyDialog from "./AddAgencyDialog";
import { Agency } from "types";
import apiClient from "api";

type Props = {
  data: Agency[];
  loading: boolean;
  fetchData: () => void;
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DataTableAgencies({
  data,
  loading,
  fetchData,
  setOpenAdd,
}: Props) {
  const navigate = useNavigate();
  const { state, dispatch } = useAppState();
  const { client, account } = state.admin;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns: ColumnDef<Agency>[] = [
    {
      accessorKey: "agency_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("agency_id")}</div>,
    },
    {
      accessorKey: "agency_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Agency name" />
      ),
      cell: ({ row }) => (
        <div
          className="hover:underline underline-offset-1 cursor-pointer"
          onClick={() => {
            onSelect(row);
            navigate("/admin/clients");
          }}
        >
          {row.getValue("agency_name")}
        </div>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => <RowAction row={row} fetchData={fetchData} />,
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const onSelect = (row: Row<Agency>) => {
    dispatch({
      type: "SET_ADMIN_AGENCY",
      payload: row.original,
    });

    if (client) {
      dispatch({
        type: "SET_ADMIN_CLIENT",
        payload: undefined,
      });
    }
    if (account) {
      dispatch({
        type: "SET_ADMIN_ACCOUNT",
        payload: undefined,
      });
    }
  };

  return (
    <CommonTable
      searchComponent={
        <div className="flex items-center py-4">
          <Input
            placeholder="Filter agencies..."
            value={
              (table.getColumn("agency_name")?.getFilterValue() as string) ?? ""
            }
            onChange={(event) =>
              table.getColumn("agency_name")?.setFilterValue(event.target.value)
            }
            className="max-w-sm"
          />

          <Button
            className="ml-auto"
            size="sm"
            onClick={() => setOpenAdd(true)}
          >
            Add agency
          </Button>
        </div>
      }
      table={table}
      name="Agencies"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface RowProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
}

const RowAction = ({ row, fetchData }: RowProps<Agency>) => {
  const { toast } = useToast();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const data = row.original;

  const deleteAgency = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting agency...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_agency", {
        headers: {
          request: JSON.stringify({
            agency_id: data.agency_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Agency deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Agency deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => {
              setOpenEdit(true);
            }}
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              agency.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteAgency}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddAgencyDialog
        open={openEdit}
        editData={data}
        fetchData={fetchData}
        onOpenChange={setOpenEdit}
      />
    </>
  );
};
