import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ChevronDown, Menu } from "lucide-react";
import { Button } from "components/ui/button";
import { buttonVariants } from "components/ui/button";
import { ScrollArea } from "components/ui/scroll-area";
import { Sheet, SheetContent } from "components/ui/sheet";

import { cn } from "lib/utils";
import { useAppState } from "context/AppContext";
import { get, set } from "utils/storage";

type LinkType = {
  title: string;
  variant: string;
  to?: string;
  disabled?: boolean;
  hidden?: boolean;
  children?: LinkType[];
};

const AdminDashboard = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [openMenu, setOpenMenu] = useState(false);

  const menuLinks = [
    {
      title: "Agencies",
      variant: "ghost",
      to: "/admin/agencies",
    },
    {
      title: "Clients",
      variant: "ghost",
      to: "/admin/clients",
      hidden: !Boolean(agency),
    },
    {
      title: "Accounts",
      variant: "ghost",
      to: "/admin/accounts",
      hidden: !Boolean(client),
      children: !Boolean(account)
        ? []
        : [
            {
              title: "Summary",
              variant: "ghost",
              to: "/admin/account/summary",
              disabled: false,
            },
            {
              title: "Account admin",
              variant: "ghost",
              children: [
                {
                  title: "Newsportal",
                  variant: "ghost",
                  to: "/admin/account/newsportal",
                },
                {
                  title: "Campaigns",
                  variant: "ghost",
                  to: "/admin/account/campaigns",
                },
                {
                  title: "Embedded messages",
                  variant: "ghost",
                  to: "/admin/account/embedded-messages",
                },
                {
                  title: "Primary LinkedIn",
                  variant: "ghost",
                  to: "/admin/account/primary-linkedin",
                },
                {
                  title: "Sitemaps",
                  variant: "ghost",
                  to: "/admin/account/sitemaps",
                },
                {
                  title: "Twitter APIs",
                  variant: "ghost",
                  to: "/admin/account/twitter-apis",
                },
                {
                  title: "Redirect builder",
                  variant: "ghost",
                  to: "/admin/account/redirect-builder",
                },
              ],
            },
            {
              title: "Content",
              variant: "ghost",
              children: [
                {
                  title: "Hint & Tips",
                  variant: "ghost",
                  to: "/admin/account/hint-subjects",
                },
                {
                  title: "Podcast",
                  variant: "ghost",
                  to: "/admin/account/podcasts",
                },
                {
                  title: "Question & Answers",
                  variant: "ghost",
                  to: "/admin/account/question-subjects",
                },
                {
                  title: "Long form",
                  variant: "ghost",
                  to: "/admin/account/long-form",
                },
              ].sort((a, b) => a.title.localeCompare(b.title)),
            },
            {
              title: "Entities",
              variant: "ghost",
              children: [
                {
                  title: "Websites",
                  variant: "ghost",
                  to: "/admin/account/websites",
                },
                {
                  title: "Avatars",
                  variant: "ghost",
                  to: "/admin/account/avatars",
                },
                {
                  title: "Pages",
                  variant: "ghost",
                  to: "/admin/account/pages",
                },
                {
                  title: "Groups",
                  variant: "ghost",
                  to: "/admin/account/groups",
                },
                // {
                //   title: "Group Answerers",
                //   variant: "ghost",
                //   to: "/admin/account/group-answerers",
                // },
              ].sort((a, b) => a.title.localeCompare(b.title)),
            },
            // {
            //   title: "Schedules",
            //   variant: "ghost",
            //   children: [
            //     {
            //       title: "Hint & Tips",
            //       variant: "ghost",
            //       to: "/admin/account/hint-schedules",
            //     },
            //     {
            //       title: "Question & Answers",
            //       variant: "ghost",
            //       to: "/admin/account/question-schedules",
            //     },
            //     {
            //       title: "Wordpress blog",
            //       variant: "ghost",
            //       to: "/admin/account/wordpress-schedules",
            //     },
            //     {
            //       title: "Avatar",
            //       variant: "ghost",
            //       to: "/admin/account/avatar-schedules",
            //     },
            //     {
            //       title: "Group",
            //       variant: "ghost",
            //       to: "/admin/account/group-schedules",
            //     },
            //   ].sort((a, b) => a.title.localeCompare(b.title)),
            // },
            {
              title: "System",
              variant: "ghost",
              children: [
                {
                  title: "Content sources",
                  variant: "ghost",
                  to: "/admin/account/content-sources",
                },
                {
                  title: "Sitemap types",
                  variant: "ghost",
                  to: "/admin/account/sitemap-types",
                },
                {
                  title: "Content types",
                  variant: "ghost",
                  to: "/admin/account/content-types",
                },
                {
                  title: "Poster types",
                  variant: "ghost",
                  to: "/admin/account/poster-types",
                },
                {
                  title: "Social channels",
                  variant: "ghost",
                  to: "/admin/account/social-channels",
                },
                {
                  title: "Post statuses",
                  variant: "ghost",
                  to: "/admin/account/post-statuses",
                },
                {
                  title: "Podcast voices",
                  variant: "ghost",
                  to: "/admin/account/podcast-voices",
                },
              ].sort((a, b) => a.title.localeCompare(b.title)),
            },
          ],
    },
  ];

  useEffect(() => {
    navigate("/admin/agencies");
    // eslint-disable-next-line
  }, []);

  const closeSidebar = () => openMenu && setOpenMenu(false);

  const Sidebar = () => (
    <ScrollArea className="h-[calc(100vh-2rem)]">
      <nav className="grid gap-1 px-4">
        {menuLinks.map(
          (link, index) =>
            !link.hidden && (
              <Fragment key={index}>
                <Link
                  to={link.to}
                  className={cn(
                    buttonVariants({
                      variant: pathname === link.to ? "default" : "ghost",
                      size: "sm",
                    }),
                    link.variant === "default" &&
                      "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                    "justify-start text-sm"
                  )}
                  onClick={closeSidebar}
                >
                  {link.title}{" "}
                </Link>
                {link.children?.map((child, index1) =>
                  child?.disabled ? (
                    <Button
                      key={index1}
                      disabled
                      variant="ghost"
                      className="justify-start pl-6 text-sm"
                    >
                      {child.title}
                    </Button>
                  ) : child.children && child.children?.length > 0 ? (
                    <ChildItems
                      item={child}
                      key={index1}
                      closeSidebar={closeSidebar}
                    />
                  ) : (
                    <Link
                      key={child.title}
                      to={child.to || "#"}
                      className={cn(
                        buttonVariants({
                          variant: pathname === child.to ? "default" : "ghost",
                          size: "sm",
                        }),

                        "justify-start pl-6 text-sm"
                      )}
                      onClick={closeSidebar}
                    >
                      {child.title}
                    </Link>
                  )
                )}
              </Fragment>
            )
        )}
      </nav>
    </ScrollArea>
  );

  return (
    <div
      className="max-w-screen-2xl mx-auto flex-1 items-start flex md:grid md:grid-cols-[220px_minmax(0,1fr)] 
    lg:grid-cols-[280px_minmax(0,1fr)]"
    >
      <div className="p-2 md:hidden h-screen border-r">
        <Button
          size="icon"
          variant="secondary"
          onClick={() => setOpenMenu(true)}
        >
          <Menu className="size-4" />
        </Button>
      </div>
      <aside className="fixed top-4 z-30 hidden h-screen w-full shrink-0 md:sticky md:block border-r py-4">
        <div className="group flex flex-col gap-4">
          <Sidebar />
        </div>
      </aside>
      <main className="relative">
        <div className="overflow-hidden p-2 md:p-4">
          <Sheet open={openMenu} onOpenChange={setOpenMenu}>
            <SheetContent side="left">
              <Sidebar />
            </SheetContent>
          </Sheet>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

const ChildItems = ({
  item,
  closeSidebar,
}: {
  item: LinkType;
  closeSidebar: () => void;
}) => {
  const [expand, setExpand] = useState(JSON.parse(get(item.title)) ?? true);
  const { pathname } = useLocation();

  useEffect(() => {
    set(item.title, JSON.stringify(expand));
    // eslint-disable-next-line
  }, [expand]);

  return (
    <>
      <Button
        variant="ghost"
        className="justify-start pl-6 text-sm"
        onClick={() => setExpand(!expand)}
      >
        {item.title}{" "}
        {item.children && (
          <ChevronDown
            className={cn(
              expand ? "rotate-0" : "-rotate-90",
              "size-4 ml-auto transition-all"
            )}
          />
        )}
      </Button>

      {expand &&
        item.children &&
        item.children.map((child1) => (
          <Link
            key={child1.title}
            to={child1.to || "#"}
            className={cn(
              buttonVariants({
                variant: pathname === child1.to ? "default" : "ghost",
                size: "sm",
              }),

              "justify-start pl-10 text-sm"
            )}
            onClick={closeSidebar}
          >
            {child1.title}
          </Link>
        ))}
    </>
  );
};

export default AdminDashboard;
