import React from "react";
import {
  Table as UITable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { ColumnDef, flexRender, RowData, Table } from "@tanstack/react-table";
import { AdminTablePagination } from "./AdminTablePagination";

type Props<TData extends RowData> = {
  table: Table<TData>;
  columns: ColumnDef<TData>[];
  loading: boolean;
  data: TData[];
  name: string;
  searchComponent?: React.ReactNode;
  showSelectedCount?: boolean;
};

const CommonTable = <TData extends RowData>({
  data,
  name,
  table,
  columns,
  loading,
  showSelectedCount,
  searchComponent,
}: Props<TData>) => {
  return (
    <div className="w-full px-1 overflow-x-auto">
      {searchComponent}
      <div className="rounded-md border min-w-full mt-4">
        <UITable>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={
                        header.id === "actions"
                          ? "sticky bg-primary-foreground right-0 z-10 py-1"
                          : "py-1"
                      }
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={
                        cell.id.includes("actions")
                          ? "sticky bg-primary-foreground right-0 z-10 py-0.5 text-xs"
                          : "py-0.5 text-xs"
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {loading ? (
                    <div className="min-h-20 flex items-center justify-center">
                      <div className="flex flex-col justify-center items-center">
                        <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                        <span className="text-sm text-center mt-4 text-primary">
                          Loading {name.toLowerCase()}...
                        </span>
                      </div>
                    </div>
                  ) : (
                    data.length === 0 && "No results."
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </UITable>
      </div>
      <AdminTablePagination
        showSelectedCount={showSelectedCount}
        table={table}
      />
    </div>
  );
};

export default CommonTable;
