import { useEffect, useState } from "react";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";

import { DataTableAvatarSchedules } from "./data";
import AddAvatarDialogSchedules from "./AddAvatarDialogSchedules";
import { Avatar, AvatarSchedule } from "types";
import apiClient from "api";

type Props = {
  selectedAvatar: Avatar | null;
};

const AvatarSchedules = ({ selectedAvatar }: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [data, setData] = useState<AvatarSchedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account || !selectedAvatar) return;
    const timeout = setTimeout(fetchAvatarSchedules, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account, selectedAvatar]);

  const fetchAvatarSchedules = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "admin_creative_get_avatar_schedules",
        {
          headers: {
            request: JSON.stringify({
              creative_id: account?.account_id,
              entity_id: selectedAvatar?.avatar_id,
            }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Avatar schedules
          </h3>
          <Button
            disabled={!selectedAvatar}
            size="sm"
            onClick={() => setOpenAdd(true)}
          >
            Add avatar schedule
          </Button>
        </div>
      </div>

      <DataTableAvatarSchedules
        data={data}
        loading={loading}
        selectedAvatar={selectedAvatar}
        fetchData={fetchAvatarSchedules}
      />

      <AddAvatarDialogSchedules
        open={openAdd}
        selectedAvatar={selectedAvatar}
        fetchData={fetchAvatarSchedules}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default AvatarSchedules;
