import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { DataTableAgencies } from "./data";
import { Agency } from "types";
import apiClient from "api";
import AddAgencyDialog from "./AddAgencyDialog";

const Agencies = () => {
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [agencies, setAgencies] = useState<Agency[]>([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchAgencies();
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  const fetchAgencies = async () => {
    try {
      const { data } = await apiClient.get("/creative_get_agencies");
      setAgencies(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>Agencies</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Agencies
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update agencies.
        </p>
      </div>

      <DataTableAgencies
        data={agencies}
        loading={loading}
        fetchData={fetchAgencies}
        setOpenAdd={setOpenAdd}
      />

      <AddAgencyDialog
        open={openAdd}
        onOpenChange={setOpenAdd}
        fetchData={fetchAgencies}
      />
    </div>
  );
};

export default Agencies;
