import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import { enGB } from "date-fns/locale";
import { DateTimePicker } from "components/ui/datetime-picker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import { GroupSchedule, Content, Poster, Group } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  selectedGroup: Group | null;
  editData?: GroupSchedule | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddGroupScheduleDialog = ({
  open,
  editData,
  selectedGroup,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [contentTypes, setContentTypes] = useState<Content[]>([]);
  const [contentList, setContentList] = useState<
    { content_id: number; description: string }[]
  >([]);
  const [posterTypes, setPosterTypes] = useState<Poster[]>([]);
  const [posterList, setPosterList] = useState<
    { poster_id: number; description: string }[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [hoursBetween, setHoursBetween] = useState(0);
  const [contentType, setContentType] = useState<Content | null>(null);

  const [content, setContent] = useState<{
    content_id: number;
    description: string;
  } | null>(null);
  const [posterType, setPosterType] = useState<Poster | null>(null);
  const [poster, setPoster] = useState<{
    poster_id: number;
    description: string;
  } | null>(null);
  const [earliestTimestamp, setEarliestTimestamp] = useState<Date | undefined>(
    undefined
  );

  useEffect(() => {
    if (!editData) return;
    setHoursBetween(editData.hours_between);
    setContentType(
      contentTypes.find((c) => c.content_type === editData.content_type) || null
    );
    setContent(
      contentList.find((c) => c.content_id === editData.content_id) || null
    );
    setPosterType(
      posterTypes.find((p) => p.content_type === editData.poster_type) || null
    );
    setEarliestTimestamp(
      editData.earliest_timestamp
        ? new Date(editData.earliest_timestamp * 1000)
        : undefined
    );
    setPoster(
      posterList.find((p) => p.poster_id === editData.poster_id) || null
    );
    // eslint-disable-next-line
  }, [editData, posterType, posterList, contentTypes, contentList]);

  useEffect(() => {
    if (!open) {
      setHoursBetween(0);
      setContentType(null);
      setContent(null);
      return;
    }

    fetchPosterTypes();
    fetchContentTypes();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!contentType) return;

    const getContent = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_content_list",
          {
            headers: {
              request: JSON.stringify({
                content_type: contentType.content_type,
                creative_id: account?.account_id,
              }),
            },
          }
        );

        const list: { content_id: number; description: string }[] =
          data?.message || [];
        setContentList(list);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    getContent();
    // eslint-disable-next-line
  }, [contentType]);

  useEffect(() => {
    if (!posterType) return;

    const getPosterList = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_poster_list",
          {
            headers: {
              request: JSON.stringify({
                poster_type: posterType.content_type,
                creative_id: account?.account_id,
              }),
            },
          }
        );

        const list: { poster_id: number; description: string }[] =
          data?.message || [];
        setPosterList(list);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    getPosterList();
    // eslint-disable-next-line
  }, [posterType]);

  const fetchContentTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_content_types");

      const content: Content[] = data?.message || [];
      setContentTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const fetchPosterTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_poster_types");

      const content: Poster[] = data?.message || [];
      setPosterTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const addGroupSchedule = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_group_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_schedule_id: editData?.group_schedule_id,
                group_id: selectedGroup?.group_id,
                content_type: contentType?.content_type,
                content_id: content?.content_id || 0,
                poster_type: posterType?.content_type,
                poster_id: poster?.poster_id || 0,
                hours_between: hoursBetween,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_group_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_id: selectedGroup?.group_id,
                content_type: contentType?.content_type,
                content_id: content?.content_id || 0,
                poster_type: posterType?.content_type,
                poster_id: poster?.poster_id || 0,
                hours_between: hoursBetween,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} group schedule
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addGroupSchedule} className="w-full grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="earliest-timestamp">Earliest timestamp</Label>
              <div className="relative">
                <DateTimePicker
                  locale={enGB}
                  value={earliestTimestamp}
                  granularity="minute"
                  onChange={setEarliestTimestamp}
                  placeholder="Pick a date & time"
                  className="w-full px-3"
                  size="lg"
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="content-type">Content type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(contentType?.content_type)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const content = contentTypes.filter(
                      (c) => c.content_type === parseInt(value)
                    );
                    setContentType(content[0] || null);
                    setContent(null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select content type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {contentTypes.length > 0 ? (
                      contentTypes.map((content) => (
                        <SelectItem
                          key={content.content_type}
                          value={String(content.content_type)}
                        >
                          {content.description}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {contentType && (
              <div className="grid gap-2">
                <Label htmlFor="content">Content</Label>
                <div className="flex gap-2">
                  <Select
                    value={String(content?.content_id)}
                    onValueChange={(value) => {
                      if (value === "no data") return;
                      const content = contentList.filter(
                        (d) => d.content_id === parseInt(value)
                      );
                      setContent(content[0] || null);
                    }}
                  >
                    <SelectTrigger type="button" className="w-full">
                      <SelectValue placeholder={"Select content"} />
                    </SelectTrigger>
                    <SelectContent>
                      {contentList.length > 0 ? (
                        contentList.map((content) => (
                          <SelectItem
                            key={content.content_id}
                            value={String(content.content_id)}
                          >
                            {content.description || content.content_id}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem value={"no data"}>No data</SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
            <div className="grid gap-2">
              <Label htmlFor="poster-type">Poster type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(posterType?.content_type)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const poster = posterTypes.filter(
                      (p) => p.content_type === parseInt(value)
                    );
                    setPosterType(poster[0] || null);
                    setPoster(null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select poster type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {posterTypes.length > 0 ? (
                      posterTypes.map((content) => (
                        <SelectItem
                          key={content.content_type}
                          value={String(content.content_type)}
                        >
                          {content.description}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {posterType && (
              <div className="grid gap-2">
                <Label htmlFor="poster">Poster</Label>
                <div className="flex gap-2">
                  <Select
                    value={String(poster?.poster_id)}
                    onValueChange={(value) => {
                      if (value === "no data") return;
                      const poster = posterList.filter(
                        (d) => d.poster_id === parseInt(value)
                      );
                      setPoster(poster[0] || null);
                    }}
                  >
                    <SelectTrigger type="button" className="w-full">
                      <SelectValue placeholder={"Select poster"} />
                    </SelectTrigger>
                    <SelectContent>
                      {posterList.length > 0 ? (
                        posterList.map((content) => (
                          <SelectItem
                            key={content.poster_id}
                            value={String(content.poster_id)}
                          >
                            {content.description || content.poster_id}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem value={"no data"}>No data</SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddGroupScheduleDialog;
