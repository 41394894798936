import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { DataTableClients } from "./data";
import { useAppState } from "context/AppContext";
import { Client } from "types";
import apiClient from "api";
import AddClientDialog from "./AddClientDialog";

const Clients = () => {
  const { state } = useAppState();
  const { agency } = state.admin;

  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    if (!agency) return;

    const timeout = setTimeout(() => {
      fetchClients();
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [agency]);

  const fetchClients = async () => {
    try {
      const { data } = await apiClient.get("/creative_get_companies", {
        headers: {
          request: JSON.stringify({ agency_id: agency?.agency_id }),
        },
      });
      setClients(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Clients</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Clients
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update and delete clients.
        </p>
      </div>

      {!agency ? (
        <p className="text-xl text-muted-foreground text-center mt-10">
          Please select an agency to view all the clients.
        </p>
      ) : (
        <DataTableClients
          data={clients}
          loading={loading}
          fetchData={fetchClients}
          setOpenAdd={setOpenAdd}
        />
      )}

      <AddClientDialog
        open={openAdd}
        onOpenChange={setOpenAdd}
        fetchData={fetchClients}
      />
    </div>
  );
};

export default Clients;
