import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "context/AppContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "components/ui/sheet";
import { Button, buttonVariants } from "components/ui/button";
import {
  ChevronDown,
  LogOutIcon,
  Menu,
  Moon,
  Sun,
  // Bell,
  // LogOut,
  // User,
  // Users,
  // Wallet,
} from "lucide-react";
// import { Avatar, AvatarFallback } from "components/ui/avatar";
import { cn } from "lib/utils";
import apiClient from "api";

const Header = () => {
  const { state, dispatch } = useAppState();
  const { isDarkMode } = state.settings;
  const { account_token } = state.user;
  const { isAdmin } = state.auth;

  const [retrievingToken, setRetrievingToken] = useState(false);

  const getTempToken = async (appName: "socialguru" | "newsguru") => {
    setRetrievingToken(true);
    try {
      const { data } = await apiClient.get("/get_temp_token", {
        headers: {
          token: account_token,
        },
      });
      const tempToken = data?.message.temporary_token || null;

      setRetrievingToken(false);
      if (tempToken) {
        redirectToNewsGuru(tempToken, appName);
      }
    } catch (error) {
      setRetrievingToken(false);
    }
  };

  // Redirect user to App B
  const redirectToNewsGuru = (
    tempToken: string,
    appName: "socialguru" | "newsguru"
  ) => {
    let url = "";
    if (appName === "newsguru") {
      url = process.env.REACT_APP_NEWSGURU_URL + "?refId=" + tempToken || "";
    }
    if (appName === "socialguru") {
      url = process.env.REACT_APP_SOCIALGURU_URL + "?refId=" + tempToken || "";
    }

    window.open(url, "_blank");
  };

  const toggleDarkMode = () => {
    dispatch({ type: "SET_DARK_MODE", payload: !isDarkMode });
  };

  return (
    <nav className="w-full backdrop-blur-sm bg-white/30 dark:bg-black/30 z-10 border-b border-gray-200 dark:border-neutral-800 px-4 py-2.5 ">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center">
          <span className="flex mr-6">
            <Link to="/">
              <img
                src={`${isDarkMode ? "/logo-white.png" : "/logo-black.png"}`}
                className="h-10"
                alt="CretiveGuru AI"
              />
            </Link>
          </span>
        </div>
        <div className="flex justify-between items-center lg:order-2  space-x-2 sm:space-x-4">
          <div className="sm:inline-block hidden">
            {isAdmin && account_token && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    size="sm"
                    disabled={retrievingToken}
                    variant="outline"
                  >
                    Apps <ChevronDown className="size-4 ml-2" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>Switch to</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <div
                      onClick={() => {
                        if (retrievingToken) return;
                        getTempToken("socialguru");
                      }}
                      className={cn(
                        buttonVariants({ variant: "secondary", size: "lg" }),
                        "w-full"
                      )}
                    >
                      Social Guru
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <div
                      onClick={() => {
                        if (retrievingToken) return;
                        getTempToken("newsguru");
                      }}
                      className={cn(
                        buttonVariants({ variant: "secondary", size: "lg" }),
                        "w-full"
                      )}
                    >
                      News Guru
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
          {/* Notification */}

          {/* <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost">
                <Bell
                  fill="currentColor"
                  className="text-gray-600 dark:text-gray-200"
                  size={20}
                  strokeWidth={2}
                />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <div className="max-w-sm">
                <div className="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-600 dark:text-gray-300">
                  Notifications
                </div>
                <div>
                  <div className="flex py-3 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
                    <div className="flex-shrink-0">
                      <img
                        className="w-11 h-11 rounded-full"
                        src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                        alt="Bonnie Green avatar"
                      />
                      <div className="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 rounded-full border border-white bg-primary-700 dark:border-gray-700">
                        <svg
                          aria-hidden="true"
                          className="w-3 h-3 text-white"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z" />
                          <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z" />
                        </svg>
                      </div>
                    </div>
                    <div className="pl-3 w-full">
                      <div className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                        New message from
                        <span className="font-semibold text-gray-900 dark:text-white">
                          Bonnie Green
                        </span>
                        : "Hey, what's up? All set for the presentation?"
                      </div>
                      <div className="text-xs font-medium text-primary-600 dark:text-primary-500">
                        a few moments ago
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DropdownMenuSeparator />
              <DropdownMenuItem>View all</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu> */}

          {/* Dark/Light Icon */}

          <Button
            onClick={toggleDarkMode}
            size="icon"
            variant="ghost"
            title="Toggle light/dark mode"
          >
            {isDarkMode ? (
              <Moon
                fill="currentColor"
                className="text-gray-600 dark:text-gray-200"
                size={20}
                strokeWidth={2}
              />
            ) : (
              <Sun
                fill="currentColor"
                className="text-gray-600 dark:text-gray-200"
                size={20}
                strokeWidth={2}
              />
            )}
          </Button>

          {/* User profile */}

          <Button
            title="Log out"
            variant="ghost"
            size="icon"
            onClick={() => {
              dispatch({ type: "LOGOUT" });
              window.location.href = "/login";
            }}
          >
            <LogOutIcon className="size-4" />
          </Button>

          {/* <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Avatar className="cursor-pointer">
                <AvatarFallback>AC</AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Austin Cooper</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <User className="mr-2 size-4" />
                User profile
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Users className="mr-2 size-4" />
                Referral
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Wallet className="mr-2 size-4" />
                Earnings
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => dispatch({ type: "LOGOUT" })}>
                <LogOut className="mr-2 size-4" />
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu> */}

          {/* Dropdown menu */}

          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="shrink-0 lg:hidden"
              >
                <Menu className="h-5 w-5" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <nav className="grid gap-6 text-lg font-medium">
                <Link
                  to="/"
                  className="flex items-center gap-2 text-lg font-semibold"
                >
                  <span className="">CreativeGuru AI</span>
                </Link>

                <Link
                  to="/"
                  className="text-muted-foreground hover:text-foreground"
                >
                  Overview
                </Link>
                <Link
                  to="/schedules"
                  className="text-muted-foreground hover:text-foreground"
                >
                  Schedules
                </Link>
                <Link
                  to="/connections"
                  className="text-muted-foreground hover:text-foreground"
                >
                  Connections
                </Link>
                <Link
                  to="/posts"
                  className="text-muted-foreground hover:text-foreground"
                >
                  Posts
                </Link>
                {account_token && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        size="sm"
                        className="w-full"
                        disabled={retrievingToken}
                        variant="secondary"
                      >
                        Apps <ChevronDown className="size-4 ml-2" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                      <DropdownMenuLabel>Switch to</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>
                        <div
                          onClick={() => {
                            if (retrievingToken) return;
                            getTempToken("socialguru");
                          }}
                          className={cn(
                            buttonVariants({
                              variant: "secondary",
                              size: "lg",
                            }),
                            "w-full"
                          )}
                        >
                          Social Guru
                        </div>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <div
                          onClick={() => {
                            if (retrievingToken) return;
                            getTempToken("newsguru");
                          }}
                          className={cn(
                            buttonVariants({
                              variant: "secondary",
                              size: "lg",
                            }),
                            "w-full"
                          )}
                        >
                          News Guru
                        </div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </nav>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </nav>
  );
};

export default Header;
