import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ImageUp, Upload, X } from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { v4 as uuidv4 } from "uuid";

import { useAppState } from "context/AppContext";
import ImageCropDialog from "./ImageCrop/ImageCropDialog";
import AccountSelect from "../AccountSelect";
import AddPodcastDialog from "./AddPodcastDialog";
import { DataTablePodcasts } from "./data";
import Presenters from "./Presenters";
import Campaigns from "./Campaigns";
import { Podcast } from "types";
import apiClient from "api";

export const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "https://wip.creativeguru.ai"
    : "https://" + window.location.host;

const PodcastVoices = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const { toast } = useToast();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const [data, setData] = useState<Podcast[]>([]);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [podcastImageUrl, setPodcastImageUrl] = useState<string>("");
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [selectedPodcast, setSelectedPodcast] = useState<Podcast | null>(null);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchPodcasts, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    if (!selectedPodcast) {
      setPodcastImageUrl("");
      return;
    }
    const timeout = setTimeout(fetchPodcastImage, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [selectedPodcast]);

  const fetchPodcasts = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_podcasts", {
        headers: {
          request: JSON.stringify({
            creative_id: account?.account_id,
          }),
        },
      });
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPodcastImage = async () => {
    setLoadingImage(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_podcast_image",
        {
          headers: {
            request: JSON.stringify({
              podcast_id: selectedPodcast?.podcast_id,
            }),
          },
        }
      );
      const url = data.message[0].image_url;
      setPodcastImageUrl(url);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoadingImage(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    if (file && file.size > 1 * 1000 * 1024) {
      toast({
        title: "File size too large",
        description: "Maximum image size allowed is 1MB",
        variant: "destructive",
      });
      return false;
    }

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file && file.size > 1 * 1000 * 1024) {
      toast({
        title: "File size too large",
        description: "Maximum image size allowed is 1MB",
        variant: "destructive",
      });
      return false;
    }

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file: Blob) => {
    if (!file) {
      console.error("No file to upload");
      return;
    }
    const mimeType = file.type;
    const extension = mimeType.split("/")[1];

    const fileName = `podcast-image-${uuidv4()}.${extension}`;
    const formData = new FormData();
    const renamedFile = new File([file], fileName, {
      type: "image/jpeg",
    });
    formData.append("file", renamedFile);
    formData.append("folder", "podcast_images/");

    const fileUrl = `${API_BASE_URL}/images/podcast_images/${fileName}`;

    setIsUploading(true);

    try {
      await axios.post(API_BASE_URL + "/images/podcast_images.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total!);
          setUploadPercentage(percentage);
        },
      });
      await saveImageUrlToDB(fileUrl);
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  const saveImageUrlToDB = async (url: string) => {
    setIsUploading(true);
    try {
      await apiClient.post(
        podcastImageUrl
          ? "/admin_creative_update_podcast_image"
          : "/admin_creative_add_podcast_image",
        {},
        {
          headers: {
            request: JSON.stringify({
              podcast_id: selectedPodcast?.podcast_id,
              image_url: url,
            }),
          },
        }
      );
      fetchPodcasts();
      await fetchPodcastImage();
      setIsUploading(true);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemove = async () => {
    setIsRemoving(true);
    try {
      await apiClient.delete("/admin_creative_delete_podcast_image", {
        headers: {
          request: JSON.stringify({
            podcast_id: selectedPodcast?.podcast_id,
          }),
        },
      });
      fetchPodcasts();
      await fetchPodcastImage();
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Podcasts</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Podcasts
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAdd(true)}>
            Add podcast
          </Button>
        </div>
      </div>

      <DataTablePodcasts
        data={data}
        loading={loading}
        selectedPodcast={selectedPodcast}
        fetchData={fetchPodcasts}
        setSelectedPodcast={setSelectedPodcast}
      />

      {selectedPodcast && (
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
              Podcast Image
            </h3>
          </div>

          {podcastImageUrl ? (
            <div className="flex flex-col items-start justi w-36 relative">
              <div className="bg-muted size-36 rounded relative">
                <img
                  src={podcastImageUrl}
                  className="object-cover w-full h-full rounded"
                  alt={selectedPodcast.podcast_title}
                />

                {isUploading && (
                  <div className="flex flex-col justify-center items-center absolute top-0 right-0 w-full h-full rounded bg-muted opacity-80">
                    <div className="size-8 border-2 border-t-2 border-muted-foreground border-t-primary rounded-full animate-spin"></div>

                    <p className="font-medium text-xs mt-1">
                      {uploadPercentage}%
                    </p>
                  </div>
                )}
              </div>

              <div className="mt-2 flex space-x-2">
                <Button
                  disabled={isRemoving}
                  size="sm"
                  variant="outline"
                  onClick={handleClick}
                >
                  Change <Upload className="size-4 ml-2" />
                </Button>
                <Button
                  disabled={isRemoving}
                  size="sm"
                  variant="outline"
                  onClick={handleRemove}
                >
                  Remove <X className="size-4 ml-2" />
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="size-36 border-dashed border border-gray-400 flex flex-col items-center justify-center rounded cursor-pointer"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={handleClick}
              >
                {loadingImage ? (
                  <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                ) : isUploading ? (
                  <div className="relative w-12 h-12">
                    <div
                      className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-primary transform rotate-45"
                      style={{
                        clipPath:
                          uploadPercentage >= 50
                            ? "circle(50% at 50% 50%)"
                            : "inset(0)",
                        transition: "all 0.5s linear",
                      }}
                    />
                    <div
                      className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-gray-200"
                      style={{
                        clipPath: "circle(50% at 50% 50%)",
                      }}
                    />
                    <div
                      className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-primary"
                      style={{
                        transform: `rotate(${uploadPercentage * 1.8}deg)`,
                        clipPath: "circle(50% at 50% 50%)",
                        transition: "all 0.5s linear",
                      }}
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <span className="font-medium text-xs">
                        {uploadPercentage}%
                      </span>
                    </div>
                  </div>
                ) : (
                  <ImageUp className="text-gray-400" />
                )}

                <p className="font-medium text-xs text-center mt-2">
                  {loadingImage
                    ? "Loading..."
                    : isUploading
                    ? "Uploading image..."
                    : "Upload image"}
                </p>
              </div>
            </div>
          )}

          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
      )}

      <Presenters selectedPodcast={selectedPodcast} />

      <Campaigns selectedPodcast={selectedPodcast} />

      <AddPodcastDialog
        open={openAdd}
        selectedPodcast={selectedPodcast}
        fetchData={fetchPodcasts}
        onOpenChange={setOpenAdd}
      />

      <ImageCropDialog
        imgSrc={imageSrc}
        onClose={() => setImageSrc(null)}
        onSave={(blobFile) => {
          uploadImage(blobFile);
          setImageSrc(null);
        }}
      />
    </div>
  );
};

export default PodcastVoices;
