import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import { Textarea } from "components/ui/textarea";
import { Group, SocialChannelType } from "types";
import apiClient from "api";
import { sanitizeString } from "utils/helper-methods";

type Props = {
  open: boolean;
  editData?: Group | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddGroupDialog = ({ open, editData, fetchData, onOpenChange }: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [groupName, setGroupName] = useState("");
  const [groupUrl, setGroupUrl] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [internal, setInternal] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [channels, setChannels] = useState<SocialChannelType[]>([]);
  const [channel, setChannel] = useState<SocialChannelType | null>(null);

  useEffect(() => {
    if (!editData) return;
    setGroupName(editData.group_name);
    setGroupUrl(editData.group_url);
    setGroupDesc(editData.group_description);
    const _channel = channels.find(
      (_d) => _d.channel_id === editData.channel_id
    );
    setChannel(_channel || null);
    setInternal(editData?.internal_group === 1 ? "yes" : "no");

    // eslint-disable-next-line
  }, [editData, channels]);

  useEffect(() => {
    if (!open) return;
    const getConnections = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_social_channels",
          {
            headers: {
              request: JSON.stringify({ creative_id: account?.account_id }),
            },
          }
        );

        const connections: SocialChannelType[] = data?.message || [];
        setChannels(connections);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    getConnections();
    // eslint-disable-next-line
  }, [open]);

  const addGroup = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_group",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                group_id: editData?.group_id,
                group_url: groupUrl,
                group_name: sanitizeString(groupName),
                group_description: sanitizeString(groupDesc),
                channel_id: channel?.channel_id,
                internal_group: internal === "yes" ? 1 : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_group",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                group_url: groupUrl,
                group_name: sanitizeString(groupName),
                group_description: sanitizeString(groupDesc),
                channel_id: channel?.channel_id,
                internal_group: internal === "yes" ? 1 : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} group</DialogTitle>
          </DialogHeader>

          <form onSubmit={addGroup} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Group name</Label>
              <Input
                required
                id="name"
                value={groupName}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setGroupName(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="url">Group URL</Label>
              <Input
                required
                id="url"
                type="url"
                value={groupUrl}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setGroupUrl(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="description">Description</Label>
              <div className="relative">
                <Textarea
                  required
                  id="subject"
                  value={groupDesc}
                  onChange={(evt) => {
                    setGroupDesc(evt.target.value);
                  }}
                  rows={4}
                  className="resize-none"
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="channel">Channel</Label>
              <Select
                required
                value={String(channel?.channel_id)}
                onValueChange={(val) => {
                  const _channel = channels.find(
                    (_d) => _d.channel_id === parseInt(val)
                  );
                  setChannel(_channel || null);
                }}
              >
                <SelectTrigger type="button" className="w-full">
                  <SelectValue placeholder={"Select channel"} />
                </SelectTrigger>
                <SelectContent>
                  {channels.length > 0 ? (
                    channels.map((item) => (
                      <SelectItem
                        key={item.channel_id}
                        value={String(item.channel_id)}
                      >
                        {item.description}
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value={"no-data"}>No data</SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="group-type">Internal group</Label>
              <Select required value={internal} onValueChange={setInternal}>
                <SelectTrigger type="button" className="w-full">
                  <SelectValue placeholder={"Select group type"} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={"yes"}>Yes</SelectItem>
                  <SelectItem value={"no"}>No</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddGroupDialog;
