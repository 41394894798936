import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Slider } from "components/ui/slider";
import { Button } from "components/ui/button";
import { Podcast, PodcastVoice, Presenter } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: Presenter | null;
  selectedPodcast: Podcast | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddPresenterDialog = ({
  open,
  editData,
  selectedPodcast,
  fetchData,
  onOpenChange,
}: Props) => {
  const [speed, setSpeed] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [presenterName, setPresenterName] = useState("");
  const [voiceTypes, setVoiceTypes] = useState<PodcastVoice[]>([]);
  const [voiceType, setVoiceType] = useState<PodcastVoice | null>(null);

  useEffect(() => {
    if (!editData) return;
    setSpeed(editData.speed);
    setPresenterName(editData.presenter_name);
    setVoiceType(
      voiceTypes.find((v) => v.voice_type === editData.voice_type) || null
    );

    // eslint-disable-next-line
  }, [editData, voiceTypes]);

  useEffect(() => {
    if (!open) {
      setSpeed(1);
      setPresenterName("");
      setVoiceType(null);
      return;
    }

    const getVoiceTypes = async () => {
      try {
        const { data } = await apiClient.get("/admin_creative_get_voices");

        const _voices: PodcastVoice[] = data?.message || [];
        setVoiceTypes(_voices);
      } catch (error) {
        console.log("error:", error);
      }
    };

    getVoiceTypes();

    // eslint-disable-next-line
  }, [open]);

  const addPresenter = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_presenter",
          {},
          {
            headers: {
              request: JSON.stringify({
                presenter_id: editData?.presenter_id,
                podcast_id: selectedPodcast?.podcast_id,
                presenter_name: presenterName,
                voice_type: voiceType?.voice_type,
                speed: speed,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_presenter",
          {},
          {
            headers: {
              request: JSON.stringify({
                podcast_id: selectedPodcast?.podcast_id,
                presenter_name: presenterName,
                voice_type: voiceType?.voice_type,
                speed: speed,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} presenter</DialogTitle>
          </DialogHeader>

          <form onSubmit={addPresenter} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Presenter name</Label>
              <Input
                required
                id="name"
                value={presenterName}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setPresenterName(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="voice-type">Voice type</Label>
              <Select
                required
                value={String(voiceType?.voice_type)}
                onValueChange={(val) => {
                  const _voice = voiceTypes.find(
                    (_v) => _v.voice_type === parseInt(val)
                  );
                  setVoiceType(_voice || null);
                }}
              >
                <SelectTrigger type="button" className="w-full">
                  <SelectValue placeholder={"Select voice"} />
                </SelectTrigger>
                <SelectContent>
                  {voiceTypes.length > 0 ? (
                    voiceTypes.map((item) => (
                      <SelectItem
                        key={item.voice_type}
                        value={String(item.voice_type)}
                      >
                        {item.description}
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value={"no-data"}>No data</SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="speed">Speed</Label>
              <div className="flex items-center gap-2">
                <span className="text-sm">0.25</span>
                <Slider
                  id="speed"
                  value={[speed]}
                  max={4}
                  min={0.25}
                  step={0.1}
                  className={"w-[60%] flex-grow"}
                  onValueChange={(value) => setSpeed(value[0])}
                />
                <span className="text-sm">4</span>
                <Input
                  className="w-20 p-1 border border-gray-300 rounded text-center text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  value={speed}
                  type="number"
                  onChange={(event) => {
                    const val = parseFloat(event.target.value);
                    setSpeed(val);
                  }}
                />
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPresenterDialog;
