import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import { enGB } from "date-fns/locale";
import { DateTimePicker } from "components/ui/datetime-picker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import {
  AuthorList,
  WordpressBlogSchedule,
  Content,
  WordpressCategories,
  WordpressConnection,
} from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: WordpressBlogSchedule | null;
  selectedWebsite: WordpressConnection | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddWpScheduleDialog = ({
  open,
  editData,
  selectedWebsite,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [contentTypes, setContentTypes] = useState<Content[]>([]);
  const [contentList, setContentList] = useState<
    { content_id: number; description: string }[]
  >([]);
  const [wpCategories, setWPCategories] = useState<WordpressCategories[]>([]);
  const [wpUsers, setWPUsers] = useState<AuthorList[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [hoursBetween, setHoursBetween] = useState(0);
  const [loadingWpData, setLoadingWpData] = useState(false);
  const [author, setAuhtor] = useState<AuthorList | null>(null);
  const [contentType, setContentType] = useState<Content | null>(null);
  const [category, setCategory] = useState<WordpressCategories | null>(null);
  const [earliestTimestamp, setEarliestTimestamp] = useState<Date | undefined>(
    undefined
  );
  const [content, setContent] = useState<{
    content_id: number;
    description: string;
  } | null>(null);

  useEffect(() => {
    if (!editData) return;
    setHoursBetween(editData.hours_between);
    setAuhtor(wpUsers.find((u) => u.id === editData.author_id) || null);
    setCategory(
      wpCategories.find((c) => c.id === editData.category_id) || null
    );
    setContentType(
      contentTypes.find((c) => c.content_type === editData.content_type) || null
    );
    setContent(
      contentList.find((c) => c.content_id === editData.content_id) || null
    );
    setEarliestTimestamp(
      editData.earliest_timestamp
        ? new Date(editData.earliest_timestamp * 1000)
        : undefined
    );
  }, [editData, wpCategories, wpUsers, contentTypes, contentList]);

  useEffect(() => {
    if (!open) {
      setHoursBetween(0);
      setAuhtor(null);
      setCategory(null);
      setContentType(null);
      setContent(null);
      return;
    }

    fetchContentTypes();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!open || !selectedWebsite) return;

    setWPCategories([]);
    setWPUsers([]);

    const getUsersCategories = async () => {
      setLoadingWpData(true);
      try {
        const { data } = await apiClient.get("/creative_get_users_categories", {
          headers: {
            request: JSON.stringify({
              wp_connection_id: selectedWebsite?.wp_connection_id,
              creative_id: account?.account_id,
            }),
          },
        });
        const categories: WordpressCategories[] =
          Object.values(data?.message?.categories) || [];
        const users: AuthorList[] = Object.values(data?.message?.users) || [];

        setWPCategories(categories);
        setWPUsers(users);
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoadingWpData(false);
      }
    };

    getUsersCategories();
    // eslint-disable-next-line
  }, [open, selectedWebsite]);

  useEffect(() => {
    if (!contentType) return;

    const getContent = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_content_list",
          {
            headers: {
              request: JSON.stringify({
                content_type: contentType.content_type,
                creative_id: account?.account_id,
              }),
            },
          }
        );

        setContentList(data?.message || []);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    getContent();
    // eslint-disable-next-line
  }, [contentType]);

  const fetchContentTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_content_types");

      const content: Content[] = data?.message || [];
      setContentTypes(content);
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
    }
  };

  const addWpSchedule = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_wpblog_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                author_id: author?.id || 0,
                hours_between: hoursBetween,
                category_id: category?.id || 0,
                content_id: content?.content_id || 0,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
                wp_blog_schedule_id: editData?.wp_blog_schedule_id,
                wp_connection_id: selectedWebsite?.wp_connection_id,
                content_type: contentType?.content_type,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_wpblog_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                author_id: author?.id || 0,
                hours_between: hoursBetween,
                category_id: category?.id || 0,
                content_id: content?.content_id || 0,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
                wp_connection_id: selectedWebsite?.wp_connection_id,
                content_type: contentType?.content_type,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {editData ? "Edit" : "Add"} website schedule
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addWpSchedule} className="w-full grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="earliest-timestamp">Earliest timestamp</Label>

              <div className="relative">
                <DateTimePicker
                  locale={enGB}
                  value={earliestTimestamp}
                  granularity="minute"
                  onChange={setEarliestTimestamp}
                  placeholder="Pick a date & time"
                  className="w-full px-3"
                  size="lg"
                />
              </div>
            </div>
            {selectedWebsite && (
              <div className="grid gap-2">
                <Label htmlFor="category">Category</Label>
                <Select
                  value={category?.id ? String(category?.id) : ""}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const wpCat = wpCategories.filter(
                      (cat) => cat.id === parseInt(value)
                    );
                    setCategory(wpCat[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue
                      placeholder={
                        loadingWpData ? "Loading..." : "Select category"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {wpCategories.length > 0 ? (
                      wpCategories.map((cat) => (
                        <SelectItem key={cat.id} value={String(cat.id)}>
                          {cat.name}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            )}

            {selectedWebsite && (
              <div className="grid gap-2">
                <Label htmlFor="author">Author</Label>
                <Select
                  value={author?.id ? String(author?.id) : ""}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const wpAuthor = wpUsers.filter(
                      (auth) => auth.id === parseInt(value)
                    );
                    setAuhtor(wpAuthor[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue
                      placeholder={
                        loadingWpData ? "Loading..." : "Select author"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {wpUsers.length > 0 ? (
                      wpUsers.map((auth) => (
                        <SelectItem key={auth.id} value={String(auth.id)}>
                          {auth.name}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            )}

            <div className="grid gap-2">
              <Label htmlFor="content-type">Content type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(contentType?.content_type)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const content = contentTypes.filter(
                      (wp) => wp.content_type === parseInt(value)
                    );
                    setContentType(content[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select content type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {contentTypes.length > 0 ? (
                      contentTypes.map((content) => (
                        <SelectItem
                          key={content.content_type}
                          value={String(content.content_type)}
                        >
                          {content.description}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {contentType && (
              <div className="grid gap-2">
                <Label htmlFor="content">Content</Label>
                <div className="flex gap-2">
                  <Select
                    value={String(content?.content_id)}
                    onValueChange={(value) => {
                      if (value === "no data") return;
                      const content = contentList.filter(
                        (d) => d.content_id === parseInt(value)
                      );
                      setContent(content[0] || null);
                    }}
                  >
                    <SelectTrigger type="button" className="w-full">
                      <SelectValue placeholder={"Select content"} />
                    </SelectTrigger>
                    <SelectContent>
                      {contentList.length > 0 ? (
                        contentList.map((content) => (
                          <SelectItem
                            key={content.content_id}
                            value={String(content.content_id)}
                          >
                            {content.description || content.content_id}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem value={"no data"}>No data</SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}

            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddWpScheduleDialog;
