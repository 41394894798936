import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { DataTableAccounts } from "./data";
import { useAppState } from "context/AppContext";
import AddAccountDialog from "./AddAccountDialog";
import { Account } from "types";
import apiClient from "api";

const Accounts = () => {
  const { state } = useAppState();
  const { agency, client } = state.admin;

  const [data, setData] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    if (!client) return;

    const timeout = setTimeout(() => {
      fetchAccounts();
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [client]);

  const fetchAccounts = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_accounts", {
        headers: {
          request: JSON.stringify({ client_id: client?.customer_id }),
        },
      });
      setData(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Accounts</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Accounts
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update and delete accounts.
        </p>
      </div>

      <div>
        {!client ? (
          <p className="text-xl text-muted-foreground text-center mt-10">
            Please select a client to view all the accounts.
          </p>
        ) : (
          <DataTableAccounts
            data={data}
            loading={loading}
            setOpenAdd={setOpenAdd}
            fetchData={fetchAccounts}
          />
        )}
      </div>

      <AddAccountDialog
        open={openAdd}
        onOpenChange={setOpenAdd}
        fetchData={fetchAccounts}
      />
    </div>
  );
};

export default Accounts;
