import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReloadIcon } from "@radix-ui/react-icons";
import { EditIcon } from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import AccountSelect from "../AccountSelect";
import { DataTableCampaigns } from "./data";
import { useAppState } from "context/AppContext";
import apiClient from "api";
import { AccountCampaign } from "types";

const Campaigns = () => {
  const { state, dispatch } = useAppState();
  const { agency, client, account } = state.admin;
  const { toast } = useToast();
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<AccountCampaign[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAttached, setIsAttached] = useState(false);

  useEffect(() => {
    setCampaigns([]);
    if (!account || !account.newsguru_email) return;
    setNewEmail(account.newsguru_email);
    const timeout = setTimeout(getCampaigns, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const attachEmail = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!account) return;
    await getCampaigns();
    dispatch({ type: "SET_ADMIN_ACCOUNT_REFRESH", payload: true });
  };

  const getCampaigns = async () => {
    setLoading(true);
    setIsEditing(false);
    try {
      const { data } = await apiClient.post(
        "/admin_creative_attach_newsguru",
        {},
        {
          headers: {
            request: JSON.stringify({
              creative_account_id: account?.account_id,
              newsguru_email: newEmail || account?.newsguru_email,
            }),
          },
        }
      );
      const campaignsData: AccountCampaign[] = data?.message || [];

      if (campaignsData.length === 1 && campaignsData[0].campaign_id === 0) {
        toast({
          title: "Not allowed",
          variant: "destructive",
          description:
            "The email address for this account cannot be attached. This account has not been created on Newsguru",
        });
        setNewEmail("");
      } else {
        setCampaigns(campaignsData);
        setIsAttached(true);
      }
      setLoading(false);
    } catch (error: any) {
      if (error?.response.status === 409) {
        toast({
          title: "Not allowed",
          variant: "destructive",
          description:
            "The email address for this account cannot be attached. This account is already linked to another Creativeguru id",
        });
        setNewEmail("");
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Campaigns</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Campaigns
          </h3>
          <AccountSelect />
        </div>
        <p className="text-base text-muted-foreground">
          {isAttached ? (
            <span className="group flex items-center">
              {account?.newsguru_email}{" "}
              <button className="ml-2" onClick={() => setIsEditing(true)}>
                <EditIcon className="cursor-pointer text-primary" size={14} />
              </button>
            </span>
          ) : (
            "Attach your Newsguru account."
          )}
        </p>
      </div>

      {(!isAttached || isEditing) && (
        <form onSubmit={attachEmail} className="mt-6 mb-4 px-1">
          <div className="flex w-full max-w-sm items-center gap-2">
            <Input
              required
              value={newEmail}
              onChange={(evt) => setNewEmail(evt.target.value)}
              type="email"
              placeholder="Newsguru Email"
            />
            <Button type="submit" disabled={loading}>
              {isEditing ? "Save" : "Attach"}
              {loading && <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />}
            </Button>
            {isEditing && (
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      )}

      <div>
        {!isAttached || !account?.newsguru_email ? (
          <p className="text-sm text-primary">
            Please enter an email to view all the campaigns.
          </p>
        ) : (
          <DataTableCampaigns
            data={campaigns}
            loading={loading}
            fetchData={getCampaigns}
          />
        )}
      </div>
    </div>
  );
};

export default Campaigns;
